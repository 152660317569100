import React, {useEffect, useState, useContext} from "react"
import { graphql, Link } from "gatsby"
import Layout from '../components/layout'
import view from "../components/blogPage/blog.module.scss";
import {BlogContext} from "../components/blogPage/context/blogContext";
import BlogResultsComponent from '../components/blogPage/blogResultsComponent'

const BlogResultes = (props) => {
    const { pageContext, data } = props
    const { edges } = data.allBlogData

    const pageSeo = {seo:{
                      MetaDesc: `All blog post results for ${pageContext.dealerName}`,
                      MetaTitle: `Blog Posts | ${pageContext.dealerName}`,
                      tags: []}
                    }

    const blogManagement = pageContext.blogmanagement;
    const allBlogPagesBottomHTML = blogManagement.allBlogPagesBottomHTML;
    const blogResultsBottomHTML = blogManagement.blogResultsBottomHTML;
    const blogResultsTopHTML = blogManagement.blogResultsTopHTML;
    const { pageNumbers, currentPage, limit, skip } = pageContext
    const blogCountTotal = data.allBlogData.pageInfo.totalCount;
    const latestPost = props.data.allBlogData.edges[0].node.blogData;
    const latestPostTitle = JSON.parse(latestPost.pageTitle);
    const latestPostContent = JSON.parse(latestPost.content);
    const latestSlug = JSON.parse(latestPost.slug);

    return (
      <Layout seo={pageSeo}>
        <div dangerouslySetInnerHTML={{ __html: blogResultsTopHTML }} />
        {currentPage === 1?
          <div className={`${view['blog-jumbotron']}`} style={{backgroundImage:`url(${latestPostContent.blogposts.thumbnailImage})`}}>
            <div style={{display:'flex', alignItems:'center', justifyContent:'center', position:'absolute', background:'rgba(0,0,0,.4)', top:0, left:0, right:0, bottom:0}}>
              <div>
                <p style={{textAlign:'center', fontWeight:900, fontSize:25, padding:'10px', maxWidth:'450px', margin:'0 auto', color:'#fff' }}>{latestPostTitle}</p>
                <Link href={latestSlug} className={`${view['blog-jumbotron-button']}`}>Read More</Link>
              </div>
            </div>
          </div>
          :
          null
        }

        <div style={{ width: "95%", margin: '25px auto', maxWidth: 1100, padding: "0 15px"}}>
          <p style={{paddingLeft:10}}>Showing {blogCountTotal} total blog results</p>
          <ul className={view["blog-entries__list"]}>

          {edges.map(data => {

            const id = data.node.blogData._id
            const pageTitle = JSON.parse(data.node.blogData.pageTitle);
            const slug = JSON.parse(data.node.blogData.slug);
            const content = JSON.parse(data.node.blogData.content);
            const images = content.blogposts.thumbnailImage;
            const datePublished = content.blogposts.publicationDate;

            return(

              <li className={view["blog-entry-card__container"]} key={id}>
                  <Link className={view["blog-entry-card"]} href={slug}>
                    <div className={view["blog-entries__image"]}>
                      <div style={{backgroundImage:`url(${images? images:null})`, backgroundSize:'contain', backgroundRepeat:'no-repeat', width:'100%', height:'100%', backgroundPosition: 'bottom'}}></div>
                    </div>
                    <div className={view["blog-entry-card__flexible"]} style={{padding:10}}>
                      {pageTitle? <h2 style={{fontSize:18, marginTop:5}}>{pageTitle}</h2> : null }
                      {datePublished? <p style={{textAlign:'left', fontStyle:'italic'}}>Posted on: {datePublished}</p> : null }
                    </div>
                  </Link>
                </li>

            )
          })}
          </ul>
          <BlogResultsComponent limit={limit} skip={skip} pageNumbers={pageNumbers} currentPage={currentPage} blogmanagement={blogManagement}/>
        </div>
        {/*BLOG RESULTS DATA */}
        <div dangerouslySetInnerHTML={{ __html: blogResultsBottomHTML }} />

        {/* ALL BLOG DATA */}
        <div dangerouslySetInnerHTML={{ __html: allBlogPagesBottomHTML }} />
      </Layout>

    )
}

export const query = graphql`
  query newQuery($skip: Int!, $limit: Int!){
    allBlogData(skip: $skip, limit:$limit, sort: {order: DESC, fields: assigned_date}){
      edges {
        node {
          blogData {
            _id
            content
            pageTitle
            slug
          }
        }
      }
      pageInfo{
        itemCount
        totalCount
        pageCount
      }
    }
  }
`;



export default BlogResultes
